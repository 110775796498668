(function hideCookieBotBannerInMobilePaths () {
  const MOBILE_PATHS = ['/sso', '/mobile'];
  const { pathname } = window.location;

  if (MOBILE_PATHS.some((path) => pathname.includes(path))) {
    const style = document.createElement('style');
    style.type = 'text/css';
    style.innerHTML = `
    #CybotCookiebotDialog,
    .CybotEdge {
      display: none !important;
    }
  `;
    document.head.appendChild(style);
  }
})();
